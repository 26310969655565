<template>
    <v-app>

        <v-container grid-list-md text-xs-center fluid>
            <h1>VPN</h1>
            <v-divider class="mb-4" />
            <div class="ma-4 text-center" v-if="loaded == false">
                <v-progress-circular size="140" width="20" color="primary" indeterminate />
            </div>
            <div v-else>
                <v-autocomplete v-model="server" :items="servers" item-value="id" item-text="name" label="Servidor VPN" deletable-chips small-chips @change="getVPN" />
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">VPN</th>
                                <th class="text-left">Placa</th>
                                <th class="text-left">Status</th>
                                <th class="text-left">Último acesso</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="v in vpn" :key="v.board">
                                <td>
                                    <ButtonVPN :IP="v.IP" />
                                </td>
                                <td>{{ v.board }}</td>
                                <td>
                                    <div v-if="boards.some(i => i.name === v.board)" class="ma-2">
                                        <v-chip v-if="$moment(v.lastAccess, 'ddd MMM DD HH:mm:ss YYYY').isBefore($moment().subtract(1, 'hour'))" class="ma-2" color="warning"
                                            text-color="black">Conexão sem atividade</v-chip>
                                        <v-chip v-else class="ma-2" color="green" text-color="white">Conexão ativa</v-chip>
                                    </div>
                                    <div v-else>
                                        <v-chip v-if="$moment(v.lastAccess, 'ddd MMM DD HH:mm:ss YYYY').isBefore($moment().subtract(1, 'hour'))" class="ma-2" color="error"
                                            text-color="white">Equipamento desconhecido</v-chip>
                                        <v-chip v-else class="ma-2" color="red accent-4" text-color="white">Equipamento desconhecido e com atividade</v-chip>
                                    </div>
                                </td>
                                <td>{{ v.lastAccess }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-container>
    </v-app>
</template>

<script>
import servers from "@/assets/json/servers.json";
import ButtonVPN from "@/components/shared/ButtonVPN";

export default {
    components: { ButtonVPN },

    data() {
        return {
            server: null,
            servers,
            vpn: [],
            boards: [],
            loaded: false
        };
    },

    created() {
        this.getBoards();
        this.server = servers[0];
        this.getVPN();
        this.timer = setInterval(this.getVPN, 5000);
    },

    methods: {
        getVPN() {
            if (this.server != null) {
                this.$http.getDynamic(this.server, "api/v2/vpn", { continuous: true }).then((result) => {
                    this.vpn = result;
                }).catch(() => {
                    this.vpn = [];
                }).finally(() => {
                    this.loaded = true;
                });
            }
        },
        getBoards() {
            this.$http.get("api/v2/board").then((result) => {
                this.boards = result;
            }).catch(() => {
                this.boards = [];
            });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
